import Cookies from 'js-cookie'


export function setAccountContinent(continent) {
    Cookies.set('account_continent', continent)
}

export function getAccountContinent() {
    return Cookies.get('account_continent')
}

export function setUserId(user_id) {
    Cookies.set('class_user_id', user_id)
}

export function getUserId() {
    return Cookies.get('class_user_id')
}

export function removeUserId() {
    Cookies.remove('class_user_id')
}

export function setUserName(user_name) {
    Cookies.set('class_user_name', user_name)
}

export function getUserName() {
    return Cookies.get('class_user_name')
}

export function removeUserName() {
    Cookies.remove('class_user_name')
}

export function setSessionUrl(user_id) {
    Cookies.set('sessionUrl', user_id)
}

export function getSessionUrl() {
    return Cookies.get('sessionUrl')
}


export function setSessionName(user_id) {
    Cookies.set('sessionName', user_id)
}

export function getSessionName() {
    return Cookies.get('sessionName')
}


export function setTestEvent(event) {
    Cookies.set('test_event', event)
}

export function getTestEvent() {
    return Cookies.get('test_event')
}

export function removeTestEvent() {
    Cookies.remove('test_event')
}


export function setUserType(user_type) {
    Cookies.set('class_user_type', user_type)
}

export function getUserType() {
    return Cookies.get('class_user_type')
}

export function removeUserType() {
    Cookies.remove('class_user_type')
}

export function getProjectCode() {
    // return '2022WEC'
    // return '22-23FBLA'
    return Cookies.get('remote_project_code')
}
export function getProjectName() {
    // return '2022 World Economics Cup'

    if (getProjectBU() == 'WEC') {
        return '2024 World Economics Cup (International Final)'
    }

    return 'My Exams'
}

export function setRemoteProjectCode(projectCode) {
    Cookies.set('remote_project_code', projectCode)
}

export function getRemoteProjectCode() {
    return Cookies.get('remote_project_code')
}

//修改语言
export function getProjectLanguage() {
    return 'english'
        // return 'chinese'
}

//修改项目
export function getProjectBU() {
    return 'WEC'
        // return 'IPSYO'
        // return 'IBO'
}
//获取topimg
export function getProjectTopImg() {

    if (getProjectBU() == 'WEC') {
        return 'wectopNavBg'
    }
    if (getProjectBU() == 'IPSYO') {
        return 'ipsyotopNavBg'
    }
    if (getProjectBU() == 'IBO') {
        return 'ibotopNavBg'
    }

    return 'WEC'
}
//获取topimg
export function getProjectHeadImg() {

    if (getProjectBU() == 'WEC') {
        return 'wechead'
    }
    if (getProjectBU() == 'IPSYO') {
        return 'ipsyohead'
    }
    if (getProjectBU() == 'IBO') {
        return 'ibohead'
    }

    return 'WEC'
}
//获取主题色 //wec:#D9AE48  ibe :
export function getProjectColor() {

    if (getProjectBU() == 'WEC') {
        return '#D9AE48'
    }
    if (getProjectBU() == 'IPSYO') {
        return '#E20043'
    }
    if (getProjectBU() == 'IBO') {
        return '#005DAB'
    }

    return 'WEC'
}



export function setLoginInfo(info) {
    Cookies.set('login_info', info)
}

export function getLoginInfo() {
    return Cookies.get('login_info')
}

export function setApplicationExtraEvents(application_extra_events) {
    Cookies.set('application_extra_events', application_extra_events)
}

export function getApplicationExtraEvents() {
    return Cookies.get('application_extra_events')
}

export function setApplicationEvents(application_events) {
    Cookies.set('application_events', application_events)
}

export function getApplicationEvents() {
    return Cookies.get('application_events')
}

export function setAppId(app_id) {
    Cookies.set('app_id', app_id)
}

export function getAppId() {
    return Cookies.get('app_id')
}

export function setIsGroup(group) {
    Cookies.set('is_group', group)
}

export function getIsGroup() {
    return Cookies.get('is_group')
}

export function setAccountCountry(account_country) {
    Cookies.set('account_country', account_country)
}

export function getAccountCountry() {
    return Cookies.get('account_country')
}

export function setAccountName(account_name) {
    Cookies.set('account_name', account_name)
}

export function getAccountName() {
    return Cookies.get('account_name')
}

export function setAvatarUrl(avatar_url) {
    Cookies.set('avatar_url', avatar_url)
}

export function getAvatarUrl() {
    return Cookies.get('avatar_url')
}

export function logout() {
    removeUserId()
    removeUserType()
    removeUserName()
    Cookies.remove('application_extra_events')
    Cookies.remove('application_events')
    Cookies.remove('app_id')
    Cookies.remove('account_name')
    Cookies.remove('avatar_url')
}

export function getUserIP(onNewIP) {
    let MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    let pc = new MyPeerConnection({
        iceServers: []
    });
    let noop = () => {};
    let localIPs = {};
    let ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
    let iterateIP = (ip) => {
        if (!localIPs[ip]) onNewIP(ip);
        localIPs[ip] = true;
    };
    pc.createDataChannel('');
    pc.createOffer().then((sdp) => {
        sdp.sdp.split('\n').forEach(function(line) {
            if (line.indexOf('candidate') < 0) return;
            line.match(ipRegex).forEach(iterateIP);
        });
        pc.setLocalDescription(sdp, noop, noop);
    }).catch((reason) => {});
    pc.onicecandidate = (ice) => {
        if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
        ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
    };
}